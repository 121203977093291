.contact-container .heading {
  font-size: 48px;
  line-height: 60px;
  font-weight: 900;
  color: #000000;
}
@media only screen and (max-width: 992px) {
  .contact-container .heading {
    font-size: 36px;
    line-height: 40px;
  }
}
