.main-banner-container {
  background-color: #fff;
}
.main-banner-container .banner-contents h2 {
  font-size: 42px;
  line-height: 50px;
  font-weight: 900;
  color: #000000;
  margin-bottom: 20px;
}
.main-banner-container .banner-contents strong {
  font-size: 24px;
  line-height: 30px;
  font-weight: 500;
  color: #000000;
  margin-bottom: 10px;
}
/* .main-banner-container .banner-contents p {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;
} */
.main-banner-container .banner-contents .images a {
  width: 35%;
}
.main-banner-container .banner-image {
  height: 410px;
}
.main-section-container .section-box {
  border-bottom: 1px solid #d9d9d9;
  width: 100%;
}
.main-section-container .heading {
  font-size: 48px;
  line-height: 60px;
  font-weight: 900;
  color: #000000;
}
.main-section-container .section-box.border-bottom-none {
  border-bottom: none;
}
/* .main-section-container .section-box .contents h3 {
  display: flex;
  width: 100%;
  font-size: 36px;
  line-height: 40px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 30px;
} */
.main-section-container .section-box .contents .section-img {
  width: 300px;
  margin-right: 30px;
}
.main-section-container .section-box .text {
  width: calc(100% - 330px);
}
.main-section-container .section-box .contents p {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #4a4a4a;
}
.work-section-container {
  background-color: #f6f6f6;
}
.work-section-container .heading {
  font-size: 48px;
  line-height: 60px;
  font-weight: 900;
  color: #000000;
}
.work-section-container .work-box {
  width: 50%;
  margin-bottom: 120px;
}
.work-section-container .work-box.last {
  margin-bottom: 0;
}
.work-section-container .work-box .line {
  position: absolute;
  left: 10%;
  bottom: -60px;
  width: 80%;
  height: 2px;
  /* background-color: #5959be; */
  background-color: #434bdf;
}
.work-section-container .work-box .line::before {
  content: "";
  width: 2px;
  height: 60px;
  position: absolute;
  left: 0;
  top: -60px;
  /* background-color: #5959be; */
  background-color: #434bdf;
}
.work-section-container .work-box .line.last::before {
  left: 0;
}
.work-section-container .work-box .line::after {
  content: "";
  width: 2px;
  height: 60px;
  position: absolute;
  right: 0;
  top: 2px;
  /* background-color: #5959be; */
  background-color: #434bdf;
}
.work-section-container .work-box .line.last::after {
  left: auto;
  right: 0;
}

.work-section-container .work-box .box {
  border-radius: 20px;
  /* background-color: #5959be; */
  background-color: #434bdf;
}
.work-section-container .work-box .box .number {
  width: 60px;
  font-weight: 700;
  font-size: 36px;
  line-height: 40px;
  color: #ffffff;
  margin-right: 20px;
}
.work-section-container .work-box .box .contents {
  width: calc(100% - 80px);
}
.work-section-container .work-box .box .contents h3 {
  color: #ffffff;
  font-size: 24px;
  line-height: 30px;
  font-weight: 600;
}
.work-section-container .work-box .box .contents p {
  color: #d9d9d9;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  margin-bottom: 0;
}

.survey-section-container .heading {
  font-size: 48px;
  line-height: 60px;
  font-weight: 900;
  color: #000000;
}
.survey-section-container .box .image {
  width: 150px;
  height: 150px;
  border-radius: 15px;
  /* background-color: #5959be; */
  background-color: #434bdf;
}
.survey-section-container .box h4 {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  color: #000000;
  margin-bottom: 15px;
}
.survey-section-container .box p {
  display: flex;
  width: 100%;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: #4a4a4a;
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .main-section-container .section-box {
    width: 100%;
  }
  .main-banner-container .banner-contents h2 {
    font-size: 36px;
  }
  .main-section-container .heading,
  .work-section-container .heading,
  .survey-section-container .heading {
    font-size: 36px;
    line-height: 40px;
  }
  .work-section-container .work-box {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .main-banner-container .banner-contents h2 {
    font-size: 24px;
    line-height: 36px;
  }
  .main-banner-container .banner-image {
    height: auto;
  }
  .main-section-container .section-box .contents h3 {
    font-size: 24px;
    line-height: 30px;
  }
  .main-section-container .section-box .contents .section-img {
    width: 100%;
  }
  .main-section-container .section-box .text {
    width: 100%;
    margin-top: 20px;
  }
  .main-section-container .heading,
  .work-section-container .heading,
  .survey-section-container .heading {
    font-size: 24px;
    line-height: 30px;
  }
  .work-section-container .work-box .box .contents h3{
    font-size: 18px;
    line-height: 24px;
  }
}
