.footer {
  background-color: #F6F6F6;
}
.footer .navbar {
  padding: 0;
}
.footer .navbar .nav-link {
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #000000;
  padding: 0;
  margin-right: 30px;
}
.footer .copy-text {
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: #000000;
}

@media only screen and (max-width: 992px) {
  .footer .copy-text {
    margin-top: 20px;
    text-align: left !important;
  }
}
@media only screen and (max-width: 767px) {
  .footer .navbar .nav-link {
    display: flex;
    width: 100%;
    margin-bottom: 15px;
    margin-right: 0;
  }
  .footer .copy-text {
    margin-top: 5px;
  }
}