*,
*::after,
*::before {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
a {
  font-family: "Open Sans", sans-serif;
}

h3 {
  font-size: 30px;
  line-height: 36px;
  font-weight: 700;
  color: #000000;
  margin-bottom: 25px;
 
}
 p {
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #4a4a4a;
}

html,
body,
#root {
  font-family: "Open Sans", sans-serif;
}

img {
  max-width: 100%;
  height: auto;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.btn-primary {
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 400;
  font-size: 20px;
  border-color: #434bdf;
  background-color: #434bdf;
  /* border-color: #5959be;
  background-color: #5959be; */
}

.btn-primary:hover,
.btn-primary:focus {
  /* border-color: #343480;
  background-color: #343480; */
  border-color: #181e9b;
  background-color: #181e9b;
}

.btn-primary.disabled,
.btn-primary:disabled {
  /* border-color: #343480;
  background-color: #343480; */
  border-color: #181e9b;
  background-color: #181e9b;
}

.btn-primary.btn-check:checked+.btn.btn-primary,
.btn.btn-primary.active,
.btn.btn-primary.show,
.btn.btn-primary:first-child:active,
:not(.btn-primary.btn-check)+.btn.btn-primary:active {
  /* border-color: #343480;
  background-color: #343480; */
  border-color: #181e9b;
  background-color: #181e9b;
}

.btn-primary:focus-visible {
  /* border-color: #343480;
  background-color: #343480; */
  border-color: #181e9b;
  background-color: #181e9b;
  box-shadow: 0 0 0 0.25rem rgba(52, 52, 128, 0.5);
}

.btn-secondary {
  padding-left: 25px;
  padding-right: 25px;
  font-weight: 400;
  font-size: 20px;
  color: #000000;
  border-color: #f6f6f6;
  background-color: #f6f6f6;
}

.btn-secondary:hover,
.btn-secondary:focus {
  color: #000000;
  border-color: #cfcfcf;
  background-color: #cfcfcf;
}

.btn-secondary.disabled,
.btn-secondary:disabled {
  color: #000000;
  border-color: #cfcfcf;
  background-color: #cfcfcf;
}

.btn-secondary.btn-check:checked+.btn.btn-secondary,
.btn.btn-secondary.active,
.btn.btn-secondary.show,
.btn.btn-secondary:first-child:active,
:not(.btn-secondary.btn-check)+.btn.btn-secondary:active {
  color: #000000;
  border-color: #cfcfcf;
  background-color: #cfcfcf;
}

.btn-secondary:focus-visible {
  color: #000000;
  border-color: #cfcfcf;
  background-color: #cfcfcf;
  box-shadow: 0 0 0 0.25rem rgba(52, 52, 128, 0.5);
}

.spinner-border {
  margin-left: 5px;
  --bs-spinner-width: 1.5rem;
  --bs-spinner-height: 1.5rem;
  --bs-spinner-border-width: 0.15em;
}

.slick-next,
.slick-prev {
  width: 36px;
  height: 36px;
  border-radius: 5px;
  z-index: 10;
}

.slick-next:before,
.slick-prev:before,
.slick-next.slick-disabled:before,
.slick-prev.slick-disabled:before {
  color: #5959be;
  opacity: 1;
  font-family: inherit;
}

@media only screen and (max-width: 580px) {
  .slick-next {
    right: 0;
  }

  .slick-prev {
    left: 0;
  }
}

.contents-container .heading {
  font-size: 48px;
  line-height: 60px;
  font-weight: 900;
  color: #000000;
}

.contents-container p {
  margin-bottom: 0;
}

@media only screen and (max-width: 992px) {
  .contents-container .heading {
    font-size: 36px;
    line-height: 40px;
  }
}
@media only screen and (max-width: 767px){
  h3{
    font-size: 18px;
    line-height: 24px;
  }
}

@media only screen and (max-width: 580px) {
  .contents-container .heading {
    font-size: 26px;
    line-height: 30px;
  }
}