.pricing-section-container {
    width: 100%;
    display: block;
}

.pricing-section-container .heading {
    font-size: 48px;
    line-height: 60px;
    font-weight: 900;
    color: #4a4a4a;
}

.pricing-section-container .table-section {
    width: 100%;
    display: block;
}

.pricing-section-container .table-section table tr th {
    font-size: 14px;
}

.pricing-section-container .table-section table tr td {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #4a4a4a;
}

.pricing-section-container .table-section table tr td .fa-check-circle {
    font-size: 20px;
    color: green;
}

.pricing-section-container .table-section table tr td .fa-info-circle {
    font-size: 20px;
    color: #434bdf;
    cursor: pointer;
    vertical-align: middle;
    margin-left: 8px;
}

.pricing-section-container .table-section table tr td:first-child {
    text-align: left;
}

.pricing-section-container .pricing-faq {
    width: 100%;
    display: block;
}

.pricing-section-container .pricing-faq .pricing-accodian-price {
    width: 100%;
    display: block;
}

.pricing-section-container .pricing-faq .pricing-accodian-price .accordion-item .accordion-header .accordion-button {
    font-size: 16px;
    line-height: 20px;
    font-weight: 600;
    color: #4a4a4a;
}

.pricing-section-container .pricing-faq .pricing-accodian-price .accordion-item .accordion-header .accordion-button:not(.collapsed) {
    background: transparent;
}

.pricing-section-container .pricing-faq .pricing-accodian-price .accordion-item .accordion-header .accordion-button:focus {
    background: transparent;
    box-shadow: none;
}

.pricing-section-container .pricing-faq .pricing-accodian-price .accordion-item .accordion-collapse .accordion-body {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #4a4a4a;
}