.header {
  border-bottom: 1px solid #f6f6f6;
}

.header .nav-link {
  position: relative;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  color: #000000;
  padding: 0px 5px 5px;
  margin-left: 10px;
  margin-right: 10px;
}

.header .nav-link.active::after {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 2px;
  content: "";
  background-color: #434bdf;
  /* background-color: #5959be; */
}

.header .nav-link.active::before {
  position: absolute;
  left: 45%;
  bottom: -8px;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  /* border-top: 8px solid #5959be; */
  border-top: 8px solid #434bdf;
  border-bottom: 0;
}

.header .dropdown-toggle {
  min-height: 42px;
  min-width: 42px;
  border-radius: 50%;
  padding: 0;
}

.header .dropdown-toggle::after {
  display: none;
}

.header .dropdown-toggle i {
  width: 40px;
  height: 40px;
  line-height: 36px;
  border-radius: 50%;
  font-size: 16px;
}

.header .dropdown-menu {
  border: none;
  background-color: #5959be;
  inset: 24px 0px auto auto !important;
}

.header .dropdown-menu .dropdown-item {
  color: #f6f6f6;
}

.header .dropdown-menu .dropdown-item:hover,
.header .dropdown-menu .dropdown-item:focus,
.header .dropdown-menu .dropdown-item:active,
.header .dropdown-menu .dropdown-item.active {
  background-color: #343480;
}

.header-buttons .btn-secondary {
  font-size: 16px;
  padding: 0px;
  position: relative;
}

.header-buttons .btn-secondary a {
  font-size: 16px;
  color: #000000;
  text-decoration: none;
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.header-buttons .btn-primary {
  font-size: 16px;
  padding: 0px;
}

.header-buttons .btn-primary a {
  font-size: 16px;
  color: #ffffff;
  text-decoration: none;
  display: block;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

@media only screen and (max-width: 1280px) {
  /* .header .nav-link {
    margin-left: 3px;
    margin-right: 3px;
  } */
}

@media only screen and (max-width: 1150px) {

  .header-buttons .btn-secondary,
  .header-buttons .btn-secondary a,
  .header-buttons .btn-primary,
  .header-buttons .btn-primary a {
    font-size: 14px;
  }

}

@media only screen and (max-width: 992px) {
  .header .nav-link.active::after {
   display: none;
  }

  .header .nav-link.active::before {
    display: none;
  }
  .header .container {
    position: relative;
  }

  .navbar {
    position: static;
  }

  .navbar-collapse {
    position: absolute;
    left: 0;
    top: 70px;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    background-color: #5959be;
  }

  .header .nav-link {
    margin-left: 0;
    margin-right: 0;
    padding: 10px 20px;
    color: #f6f6f6;
  }

  .navbar-nav .nav-link.active,
  .navbar-nav .nav-link.show {
    color: #ffffff;
    font-weight: 500;
  }
}

@media only screen and (max-width: 767px) {
 
}